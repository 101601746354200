import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isEmpty, noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default class BuatDataKlsForm extends Component{
  constructor(props) {
    super(props);
    this.state = {
      edit: props.status === !isEmpty(props.data),
      allOption: {
        label: "Select all",
        value: "*"
      }
    };
    // this.multiselectRef = React.createRef();
    this._onFormChange = this._onFormChange.bind(this);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

render(){
  const { onFormChange, form, classes, levels, param, prm_classes } = this.props;
  const Major = [
    { label: 'IPA', value: '1' },
    { label: 'IPS', value: '2' },
    { label: 'BAHASA', value: '3' },
    { label: 'UMUM', value: '4' },
  ];
  const Period = [
    { label: '2019', value: '1' },
    { label: '2020', value: '2' },
    { label: '2021', value: '3' },
  ];
  const report_periods = [
    { label: 'Catur Wulan [4 bulan]', value: '1' },
    { label: 'Semester [6 bulan]', value: '2' },
  ];
  const major = [
    { label: 'IPA', value: '11'},
    { label: 'IPS', value: '12'},
    { label: 'Umum', value: '13'},
    { label: 'PLAYGROUP', value: '17'},
    { label: 'KINDERGARTEN', value: '18'},
  ]
  return (
    <div className="nilai-kognitif__custom-form">
      <div className="nilai-kognitif__custom-form-row-class">
        <div className="nilai-kognitif__custom-form-row-class__field">
          <Select
            name="levels_id"
            label="Unit"
            onChange={onFormChange}
            data={param.levels}
            disabled
            value={form.value.levels_id}
            placeholder={'Pilihan'}
            error={form.error.levels_id || ''}
          />
           
        </div>
        <div className="nilai-kognitif__custom-form-column__field">
          <Select
            name="classes_id"
            label="Kelas"
            onChange={onFormChange}
            data={ param.classes}
            value={form.value.classes_id}
            placeholder={'Pilihan'}
            error={form.error.classes_id || ''}
          />
        </div>
      </div>
      <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
          <Input
            type='text'
            name="name"
            label="Nama Kelas"
            onChange={onFormChange}
            value={form.value.name}
            error={form.error.name || ''}
          />
        </div>
        <div className="nilai-kognitif__custom-form-column__field">
          <Input 
            type='number'
            name="capacity"
            label="Daya Tampung"
            onChange={onFormChange}
            value={form.value.capacity || ''}
            error={form.error.capacity || ''}
          />
          {/* <Input 
            type='text'
            name="other_class_name"
            label="Nama Kelas Lain"
            onChange={onFormChange}
            value={form.value.other_class_name}
            error={form.error.other_class_name || ''}
          /> */}
        </div>
      </div>
      <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
          <Select
            name="homeroom_teacher_id"
            label="Wali Kelas"
            onChange={onFormChange}
            data={param.teacher}
            value={form.value.homeroom_teacher_id}
            error={form.error.homeroom_teacher_id || ''}
            placeholder={'Pilihan'}
          />
        </div>
        <div className="nilai-kognitif__custom-form-column__field">
          {/* <Select
            name="majors_id"
            label="Jurusan"
            onChange={onFormChange}
            data={param.major}
            value={form.value.majors_id}
            error={form.error.majors_id || ''}
            placeholder={'Pilihan'}
          /> */}
        </div>
      </div>
      <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
          {/* <Input
            type='text'
            name="homeroom_teacher"
            label="Wali Kelas"
            onChange={onFormChange}
            value={form.value.homeroom_teacher}
            error={form.error.homeroom_teacher || ''}
        /> */}
        </div>
        <div className="nilai-kognitif__custom-form-column__field">
          {/* <Input
            type='text'
            name="subtitute_homeroom_teacher"
            label="Wali Kelas Kedua (Opsional)"
            onChange={onFormChange}
            value={form.value.subtitute_homeroom_teacher}
            error={form.error.subtitute_homeroom_teacher || ''}
          /> */}
          {/* <Select
            name="subtitute_homeroom_teacher_id"
            label="Wali Kelas Kedua (Opsional)"
            onChange={onFormChange}
            data={param.teacher}
            value={form.value.subtitute_homeroom_teacher_id}
            error={form.error.subtitute_homeroom_teacher_id || ''}
            placeholder={'Pilihan'}
          /> */}
        </div>
      </div>
      {/* <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
        <Select
          name="report_periods"
          label="Sistem Sekolah"
          onChange={onFormChange}
          data={report_periods}
          value={form.value.report_periods}
          error={form.error.report_periods || ''}
          placeholder={'Pilihan'}
        />       
        </div>
        <div className="nilai-kognitif__custom-form-column__field">
        
        </div>
      </div> */}
    </div>
        
 
   
  );
}
  
}
BuatDataKlsForm.propTypes = {
  classes: PropTypes.array,
  levels: PropTypes.array,
  prm_classes: PropTypes.array,
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
BuatDataKlsForm.defaultProps = {
  onFormChange: noop,
  classes: [],
  levels: [],
  prm_classes: [],
};
