import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop } from "lodash";
import { Input, Select, InputDate, Textarea } from "../../../components/base/index";

export default class PPDBOrtuForm extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);

    this.state = {
    };
  }

  _onFormChange(e) {
    const { onChange } = this.props;
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change, () => {
      console.log(this.state);
    });

    onChange(e);
  }

  render() {
    const { form, idx, onChange, classes, param } = this.props;

    if (form.value.father_provincess_id == "") {
      form.value.father_city_id = "";
    }
    if (form.value.father_city_id == "") {
      form.value.father_districts_id = "";
    }
    if (form.value.father_districts_id == "") {
      form.value.father_sub_districts_id = "";
    }
    
    return (
      <div className="manage-registration__custom-form">
        <h1>Data Orang Tua/Wali</h1>
        <h1>Ayah</h1>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="father_name"
              label="Nama Lengkap *"
              onChange={this._onFormChange}
              value={form.value.father_name}
              error={form.error.father_name || ""}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="father_nik"
              label="NIK *"
              onChange={this._onFormChange}
              value={form.value.father_nik}
              error={form.error.father_nik || ""}
              isRequired
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="father_religions_id"
              label="Agama *"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.father_religions_id}
              error={form.error.father_religions_id || ""}
              data={param.religions}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="father_pob"
              label="Tempat Lahir *"
              onChange={this._onFormChange}
              value={form.value.father_pob}
              error={form.error.father_pob || ""}
              isRequired
            />
            <div className="manage-registration__custom-form-margin-left">
              <InputDate
                type="date"
                name="father_dob"
                label="Tanggal Lahir *"
                onChange={this._onFormChange}
                value={form.value.father_dob}
                error={form.error.father_dob || ""}
                isRequired
              />
            </div>
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            {/* <Select
              name="father_citizenships_id"
              label="Kewarganegaraan"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.father_citizenships_id}
              error={form.error.father_citizenships_id || ""}
              data={param.citizenships}
            /> */}
            <Select
              name="father_educations_id"
              label="Pendidikan Terakhir"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.father_educations_id}
              error={form.error.father_educations_id || ""}
              data={param.educations}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="father_phone"
              label="No Telpon *"
              onChange={this._onFormChange}
              value={form.value.father_phone}
              error={form.error.father_phone || ""}
              isRequired
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="father_occupations_id"
              label="Pekerjaan *"
              onChange={this._onFormChange}
              data={param.occupations}
              placeholder="Pilihan"
              value={form.value.father_occupations_id}
              error={form.error.father_occupations_id || ""}
              isRequired
            />
          </div>

          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="father_salary"
              label="Penghasilan *"
              onChange={this._onFormChange}
              value={form.value.father_salary}
              error={form.error.father_salary || ""}
              isRequired
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <Textarea
            type="text"
            name="father_address"
            label="Alamat Tempat Tinggal *"
            onChange={this._onFormChange}
            value={form.value.father_address}
            error={form.error.father_address || ""}
            isRequired
          />
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              name="father_status"
              label="Status/Kondisi"
              onChange={this._onFormChange}
              value={form.value.father_status}
              error={form.error.father_status || ""}
            />
          </div>

          <div className="manage-registration__custom-form-row__field">
          </div>
        </div>

        {/* <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              name="father_provincess_id"
              value={form.value.father_provincess_id}
              error={form.error.father_provincess_id || ""}
              placeholder="Pilihan"
              label="Propinsi"
              onChange={this._onFormChange}
            />
          </div>  
          <div className="manage-registration__custom-form-row__field">
            {form.value.father_provincess_id && (
              <Input
                name="father_city_id"
                label="Kabupaten/Kota"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.father_city_id}
                error={form.error.father_city_id || ""}
              />
            )}
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            {form.value.father_city_id && (
              <Input
                name="father_districts_id"
                label="Kecamatan"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.father_districts_id}
                error={form.error.father_districts_id || ""}
              />
            )}
          </div>
          <div className="manage-registration__custom-form-row__field">
            {form.value.father_districts_id && (
              <Input
                name="father_sub_districts_id"
                label="Kelurahan"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.father_sub_districts_id}
                error={form.error.father_sub_districts_id || ""}
              />
            )}
          </div>
        </div> */}
      </div>
    );
  }
}
PPDBOrtuForm.propTypes = {
  idx: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  list: PropTypes.array.isRequired,
};
PPDBOrtuForm.defaultProps = {
  onChange: noop,
  father_city_id: [],
  father_districts_id: [],
};