import { connect } from 'react-redux';
import PembagianKlsAjaranBaru from '../../../views/Akademi/DataKelas/PembagianKlsAjaranBaru.view';
import { listStudentsPPDB, saveClassDivPPDB, listStudentsClassDiv, saveNextClassDiv, listPPDBOffline, saveClassPPDBOffline } from '../../../states/thunks/academics.thunk'
import { getPeriodsOptions, getPrmLevels, getClassesOptions, getNewStudentOptions, getClassroomOptions, getStudentPPDB, getStudentNextClass, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleListStudentsPPDB: (payload, goback) => dispatch(listStudentsPPDB(payload, goback)),
    handleSaveClassDivPPDB: (payload, goback) => dispatch(saveClassDivPPDB(payload, goback)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getNewStudentOptions: (payload) => dispatch(getNewStudentOptions(payload)),
    getClassroomsOptions: (payload) => dispatch(getClassroomOptions(payload)),
    getStudentPPDBOptions: (payload) => dispatch(getStudentPPDB(payload)),
    getStudentNextClassOptions: (payload) => dispatch(getStudentNextClass(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListStudentsClassDiv: (payload, goback) => dispatch(listStudentsClassDiv(payload, goback)),
    handleSaveNextClassDiv: (payload, goback) => dispatch(saveNextClassDiv(payload, goback)),
    handleListPPDBOffline:(payload, goback) => dispatch(listPPDBOffline(payload, goback)),
    handleSaveClassPPDBOffline:(payload, goback) => dispatch(saveClassPPDBOffline(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PembagianKlsAjaranBaru);