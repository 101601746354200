import language from "../languages";
import homeIcon from "../assets/img/menu/beranda.png";
import personalIcon from "../assets/img/menu/personalia.png";
import financeIcon from "../assets/img/menu/penganggaran.png";
import settingIcon from "../assets/img/menu/pengaturan.png";
import studentIcon from "../assets/img/menu/kesiswaan.png";
import curiculumIcon from "../assets/img/menu/kurikulum.png";
import administrationIcon from "../assets/img/menu/administrasi.png";

export const SIDEBAR__DASHBOARD_MENU = [
  {
    title: language.translate.SIDEBAR__HOME,
    iconImage: homeIcon,
    path: "/dashboard",
  },
  {
    title: language.translate.SIDEBAR__STUDENT,
    iconImage: studentIcon,
    path: "/dashboard/kesiswaan",
    menu: [
      // {
      //   //   title: language.translate.SIDEBAR_STUDENT_REGISTRATION,
      //   //   path: '/dashboard/kesiswaan/pendaftaran',
      //   //   icon: 'icon-minus',
      //   //   className: 'menu__left-icon--small',
      //   //   padding: 20,
      //   // }, {
      //   title: "Penerimaan Siswa Baru",
      //   path: "/dashboard/kesiswaan/list-pendaftaran-siswa",
      //   icon: "icon-minus",
      //   className: "menu__left-icon--small",
      //   padding: 20,
      // },
      {
        title: "Penerimaan Siswa",
        icon: "icon-minus",
        path: "/dashboard/kesiswaan/penerimaan-siswa",
        className: "menu__left-icon--small",
        padding: 20,
        menu: [
          {
            title: "Penerimaan Siswa Baru",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/list-pendaftaran-ppdb",
            permission: "ppdb_offline",
          },
          {
            title: "Mutasi Siswa",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/list-pendaftaran-siswa",
          },
        ],
      },
      {
        title: "Data Siswa",
        icon: "icon-minus",
        path: "/dashboard/kesiswaan/data-siswa",
        className: "menu__left-icon--small",
        padding: 20,
        menu: [
          {
            title: "Daftar Siswa",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/laporan/daftar-siswa",
          },
          // {
          //   title: "Daftar Riwayat Siswa",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/kesiswaan/data-siswa/daftar-riwayat-siswa",
          // },
          {
            title: "Laporan PPDB",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/data-siswa/daftar-siswa-ppdb",
          },
          // {
          //   title: "Catat Siswa Pindah",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/kesiswaan/data-siswa/catat-siswa-pindah",
          // },
          // // {
          // //   title: 'Pembatalan Siswa Pindah',
          // //   icon: 'icon-angle-right',
          // //   className: 'menu__left-icon--small',
          // //   padding: 40,
          // //   path: '/dashboard/kesiswaan/data-siswa/pembatalan-siswa-pindah',
          // // }
          // {
          //   title: "Catat Siswa Keluar",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/kesiswaan/data-siswa/catat-siswa-keluar",
          // },
        ],
      },
      // {
      //   title: "Absensi Siswa",
      //   icon: "icon-minus",
      //   path: "/dashboard/kesiswaan/absensi-siswa",
      //   className: "menu__left-icon--small",
      //   padding: 20,
      //   menu: [
      //     {
      //       title: "Absensi Harian",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kesiswaan/absensi-siswa/absensi-harian",
      //     },
      //     {
      //       title: "Absensi Rekap",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kesiswaan/absensi-siswa/absensi-rekap",
      //     },
      //     {
      //       title: "Isi per Bidang Study",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kesiswaan/absensi-siswa/isi-perbidang-study",
      //     },
      //     {
      //       title: "Laporan Absen Bidang Study",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kesiswaan/absensi-siswa/laporan-absen-bidang-study",
      //     },
      //   ],
      // },
      // {
      //   title: "Displiner",
      //   icon: "icon-minus",
      //   path: "/dashboard/kesiswaan/displiner",
      //   className: "menu__left-icon--small",
      //   padding: 20,
      //   menu: [
      //     {
      //       title: "Table Pelanggaran",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kesiswaan/displiner/table-pelanggaran",
      //     },
      //     {
      //       title: "Table Prestasi",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kesiswaan/displiner/table-prestasi",
      //     },
      //     {
      //       title: "Catat Pelanggaran",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kesiswaan/displiner/catat-pelanggaran",
      //     },
      //     {
      //       title: "Catat Prestasi",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kesiswaan/displiner/catat-prestasi",
      //     },
      //     // {
      //     //   title:'Analisa Displiner',
      //     //   icon: 'icon-angle-right',
      //     //   className: 'menu__left-icon--small',
      //     //   padding: 40,
      //     //   path: '/dashboard/kesiswaan/displiner/analisa-displiner',
      //     // },
      //     {
      //       title: "Catat Harian Siswa",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kesiswaan/displiner/catat-harian-siswa",
      //     },
      //   ],
      // },
      // {
      //   title: "Ekstrakulikuler",
      //   icon: "icon-minus",
      //   path: "/dashboard/kesiswaan/ekstrakulikuler",
      //   className: "menu__left-icon--small",
      //   padding: 20,
      //   menu: [
      //     {
      //       title: "Jenis Ekstrakulikuler",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kesiswaan/ekstrakulikuler/jenis-ekstrakulikuler",
      //     },
      //     {
      //       title: "Daftar Peserta Ekskul",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kesiswaan/ekstrakulikuler/daftar-peserta-ekskul",
      //     },
      //     {
      //       title: "Pembagian Kelas Ekskul",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul",
      //     },
      //     {
      //       title: "Absensi Ekskul",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kesiswaan/ekstrakulikuler/absensi-ekskul",
      //     },
      //     {
      //       title: "Pengisian Nilai Ekskul",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kesiswaan/ekstrakulikuler/pengisian-nilai-ekskul",
      //     },
      //   ],
      // },
      // {
      //   title: "Bimbingan Konseling",
      //   path: "/dashboard/kesiswaan/bimbingan-konseling/konseling-siswa",
      //   icon: "icon-minus",
      //   className: "menu__left-icon--small",
      //   padding: 20,
      // },
      {
        title: "Laporan Kesiswaan",
        icon: "icon-minus",
        className: "menu__left-icon--small",
        path: "/dashboard/kesiswaan/laporan",
        padding: 20,
        menu: [
          {
            title: "Klapper",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/laporan/klapper",
          },
          // {
          //   title: "Laporan 8355",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/kesiswaan/laporan/laporan-8355",
          // },
          // {
          //   title: "Laporan Displiner",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/kesiswaan/laporan/laporan-displiner",
          // },
          // {
          //   title: "Laporan Konseling",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/kesiswaan/laporan/laporan-konseling",
          // },
          // {
          //   title: 'Laporan Absensi Kelas',
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/kesiswaan/laporan/laporan-absensi-kelas',

          // },{
          //   title: 'Laporan Persensi',
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/kesiswaan/laporan/laporan-persensi',

          // }
        ],
      },
    ],
  },
  {
    title: "Tata Usaha",
    iconImage: administrationIcon,
    path: "/dashboard/tata-usaha",
    menu: [
      {
        title: "Manajemen Kelas",
        icon: "icon-minus",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tata-usaha/manajemen-kelas",
        menu: [
          // {
          //   title: "Laporan Sekolah",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/manajemen-kelas/laporan-sekolah",
          // },
          // {
          //   title: "Jurusan Kelas",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/manajemen-kelas/jurusan-kelas",
          // },
          // {
          //   title: "Pembagian Kelas",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/manajemen-kelas/pembagian-kelas",
          // },
          {
            title: "Kenaikan Kelas Siswa",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/tata-usaha/manajemen-kelas/kenaikan-kelas-siswa",
          },
          {
            title: "Pembagian Kelas PPDB",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/tata-usaha/manajemen-kelas/pembagian-kelas-ppdb",
          },
          {
            title: "Laporan Kelas Tahun Ajaran Baru",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/tata-usaha/manajemen-kelas/pengaturan-kelas-ajaran-baru",
          },
         
          {
            title: "Siswa Pindah Kelas",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/tata-usaha/manajemen-kelas/siswa-pindah-kelas",
          },
          // {
          //   title: "Pindah Unit",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/manajemen-kelas/pindah-unit",
          // },
          // {
          //   title: "Daftar Pindah Unit",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/manajemen-kelas/daftar-pindah-unit",
          // },
          // {
          //   title: "Permohonan Pindah Unit",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/manajemen-kelas/permohonan-pindah-unit",
          // },
        ],
      },
      {
        title: "Laporan Individual Sekolah",
        icon: "icon-minus",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tata-usaha/laporan-individual-sekolah",
        menu: [
          // {
          //   title: "Anak Putus Sekolah",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/laporan-individual-sekolah/anak-putus-sekolah",
          // },
          // {
          //   title: "Lulusan Tidak Melanjutkan",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/laporan-individual-sekolah/lulusan-tidak-melanjutkan",
          // },
          {
            title: "Pendidikan Guru",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/tata-usaha/laporan-individual-sekolah/pendidikan-guru",
          },
          // {
          //   title: "Pendidikan Guru S1 - S3",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/laporan-individual-sekolah/pendidikan-guru-s1-s3",
          // },
          // {
          //   title: "Guru Belum Sertifikat Pendidik",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/laporan-individual-sekolah/guru-belum-sertifikat-pendidik",
          // },
        ],
      },
    ],
  },
  // {
  //   title: language.translate.SIDEBAR__CURRICULUM,
  //   iconImage: curiculumIcon,
  //   path: "/dashboard/kurikulum",
  //   menu: [
  //     {
  //       title: "Ulangan",
  //       icon: "icon-minus",
  //       path: "/dashboard/kurikulum/ulangan",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //       menu: [
  //         {
  //           title: "Pengisian Indikator Sikap",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/kurikulum/ulangan/pengisian-indikator-sikap",
  //         },
  //         {
  //           title: "Pengisian Nilai Kognitif, Psik & Afek",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif",
  //         },
  //         {
  //           title: "Pengisian Observasi & Daftar Nilai",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/kurikulum/ulangan/pengisian-observasi-harian",
  //         },
  //         {
  //           title: "Daftar Nilai",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/kurikulum/ulangan/daftar-nilai",
  //         },
  //         {
  //           title: "Pengisian Aspek Perkembangan & Kompetensi Dasar",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/kurikulum/ulangan/pengisian-kompetensi-dasar",
  //         },
  //         {
  //           title: "Catatan Anekdot",
  //           icon: "icon-minus",
  //           path: "/dashboard/kurikulum/ulangan/catatan-anekdot",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           // permission: "view_ekskul_guru",
  //           menu: [
  //             {
  //               title: "Tema",
  //               icon: "icon-angle-right",
  //               className: "menu__left-icon--small",
  //               padding: 60,
  //               path: "/dashboard/kurikulum/ulangan/catatan-anekdot/tema",
  //               // permission: "view_ekskul_guru",
  //             },
  //             {
  //               title: "Sub Tema",
  //               icon: "icon-angle-right",
  //               className: "menu__left-icon--small",
  //               padding: 60,
  //               path: "/dashboard/kurikulum/ulangan/catatan-anekdot/sub-tema",
  //               // permission: "view_ekskul_guru",
  //             },
  //             {
  //               title: "Pengisian Catatan Anekdot",
  //               icon: "icon-angle-right",
  //               className: "menu__left-icon--small",
  //               padding: 60,
  //               path: "/dashboard/kurikulum/ulangan/catatan-anekdot/pengisian-catatan-anekdot",
  //               // permission: "view_ekskul_guru",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: "Pengumuman",
  //       icon: "icon-minus",
  //       path: "/dashboard/kurikulum/Pengumuman",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //     },
  //     {
  //       title: "Jadwal Kelas & Karyawan",
  //       icon: "icon-minus",
  //       path: "/dashboard/kurikulum/jadwal/jadwal-per-kelas",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //     },
  //     {
  //       title: "Mata Pelajaran",
  //       icon: "icon-minus",
  //       path: "/dashboard/kurikulum/mata-pelajaran",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //       menu: [
  //         {
  //           title: "Kode Mata Pelajaran",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/kurikulum/mata-pelajaran/kode-mata-pelajaran",
  //         },
  //         {
  //           title: "Daftar Guru",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/kurikulum/mata-pelajaran/daftar-guru",
  //         },
  //         {
  //           title: "Jadwal Matapelajaran",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/kurikulum/mata-pelajaran/jadwal-mata-pelajaran",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Raport",
  //       icon: "icon-minus",
  //       path: "/dashboard/kurikulum/raport",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //       menu: [
  //         {
  //           title: "Daftar Nilai Raport",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/kurikulum/raport/daftar-nilai-raport",
  //         },
  //       ],
  //     },
  //     // {
  //     //   title: 'Analisa',
  //     //   icon: 'icon-minus',
  //     //   path: '/dashboard/kurikulum/analisa',
  //     //   className: 'menu__left-icon--small',
  //     //   padding: 20,
  //     //   menu: [{
  //     //     title: 'Estimasi Kenaikan Kelas',
  //     //     icon: 'icon-angle-right',
  //     //     className: 'menu__left-icon--small',
  //     //     padding: 40,
  //     //     path: '/dashboard/kurikulum/analisa/estimasi-kenaikan-kelas',

  //     //   },{
  //     //     title: 'Komposisi Siswa',
  //     //     icon: 'icon-angle-right',
  //     //     className: 'menu__left-icon--small',
  //     //     padding: 40,
  //     //     path: '/dashboard/kurikulum/analisa/komposisi-siswa',

  //     //   }],
  //     // },
  //     // {title: 'Soal',
  //     // icon: 'icon-minus',
  //     // path: '/dashboard/kurikulum/soal',
  //     // className: 'menu__left-icon--small',
  //     // padding: 20,
  //     // menu: [{
  //     //   title: 'List Soal',
  //     //   icon: 'icon-angle-right',
  //     //   className: 'menu__left-icon--small',
  //     //   padding: 40,
  //     //   path: '/dashboard/kurikulum/soal/list-soal',
  //     // }, {
  //     //   title: 'Pengaturan Soal',
  //     //   path: '/dashboard/kurikulum/soal/pengaturan-soal',
  //     //   icon: 'icon-angle-right',
  //     //   className: 'menu__left-icon--small',
  //     //   padding: 40,
  //     // }],

  //     // }
  //   ],
  // },
  // {
  //   title: language.translate.SIDEBAR__FINANCE,
  //   iconImage: financeIcon,
  //   path: '/dashboard/keuangan',
  //   menu: [{
  //     title: language.translate.SIDEBAR__FINANCE__BUDGETING,
  //     icon: 'icon-minus',
  //     className: 'menu__left-icon--small',
  //     padding: 20,
  //     path: '/dashboard/keuangan/penganggaran',
  //     menu: [{
  //       title: language.translate.SIDEBAR__FINANCE__BUDGETING__BUDGET,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/penganggaran/anggaran',
  //     }, /* {
  //       title: language.translate.SIDEBAR__FINANCE__BUDGETING__NON_BUDGET,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/penganggaran/non-anggaran',
  //     }, {
  //       title: language.translate.SIDEBAR__FINANCE__BUDGETING__BUDGET_TRANSFER,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/penganggaran/pengalihan-anggaran',
  //     }, */{
  //       title: language.translate.SIDEBAR__FINANCE__BUDGETING__FUND_REQUEST,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/penganggaran/permohonan-dana',
  //     }, {
  //       title: language.translate.SIDEBAR__FINANCE__BUDGETING__REALIZATION,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/penganggaran/realisasi',
  //     }, {
  //       title: language.translate.SIDEBAR__FINANCE__BUDGETING__RECAPS,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/penganggaran/rekapitulasi',
  //       permission: 'view_budget_recaps',
  //     }],
  //   }, {
  //     title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING,
  //     icon: 'icon-minus',
  //     className: 'menu__left-icon--small',
  //     padding: 20,
  //     path: '/dashboard/keuangan/pembukuan',
  //     menu: [{
  //       title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__BALANCE,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/pembukuan/saldo-awal',
  //     }, {
  //       title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__CASH_JOURNAL,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/pembukuan/jurnal-kas',
  //       attributes: {
  //         journal_type: 'KAS',
  //       },
  //     }, {
  //       title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__BANK_JOURNAL,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/pembukuan/jurnal-bank',
  //       attributes: {
  //         journal_type: 'BANK',
  //       },
  //     },  {
  //       title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__H2H_JOURNAL,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/pembukuan/jurnal-h2h',
  //       attributes: {
  //         journal_type: 'H2H',
  //       },
  //     }, {
  //       title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__GENERAL_JOURNAL,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/pembukuan/jurnal-umum',
  //     }, {
  //       title: 'Jurnal Kas Penutup',
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/pembukuan/jurnal-kas-penutup',
  //       attributes: {
  //         journal_type: 'KAS',
  //       },
  //     }, {
  //     //   title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__PAYMENT_JOURNAL,
  //     //   icon: 'icon-angle-right',
  //     //   className: 'menu__left-icon--small',
  //     //   padding: 40,
  //     //   path: '/dashboard/keuangan/pembukuan/jurnal-pembayaran',
  //     // }, {
  //       title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__ADJUSTMENT_JOURNAL,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/pembukuan/jurnal-penyesuaian',
  //       permission: 'adjust_journal',
  //     }, /* , {
  //       title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__CLOSING_JOURNAL,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/pembukuan/jurnal-penutup',
  //     }, {
  //       title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__NON_KAS_JOURNAL,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/pembukuan/jurnal-non-kas',
  //     } */],
  //   }, {
  //     title: language.translate.SIDEBAR__FINANCE__REPORT,
  //     icon: 'icon-minus',
  //     className: 'menu__left-icon--small',
  //     padding: 20,
  //     menu: [{
  //       title: language.translate.SIDEBAR__FINANCE__REPORT__PAYMENT,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/laporan/pembayaran-uang-sekolah',
  //     }, {
  //       title: language.translate.SIDEBAR__FINANCE__REPORT__AR,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/laporan/tunggakan',
  //     }, {
  //       title: language.translate.SIDEBAR__FINANCE__REPORT__PAYMENT_RECAP,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/laporan/rekap-pembayaran',
  //     }, {
  //       title: language.translate.SIDEBAR__FINANCE__REPORT__CASH_JOURNAL,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/laporan/jurnal-kas',
  //     }, {
  //       title: language.translate.SIDEBAR__FINANCE__REPORT__BANK_JOURNAL,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/laporan/jurnal-bank',
  //     }, {
  //       title: language.translate.SIDEBAR__FINANCE__REPORT__BIG,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/laporan/buku-besar',
  //     }, /* {
  //       title: language.translate.SIDEBAR__FINANCE__REPORT__MONTH,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/laporan/transaksi-bulanan',
  //     }, {
  //       title: language.translate.SIDEBAR__FINANCE__REPORT__DAILY_MONTH,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/laporan/jurnal-harian-dan-bulanan',
  //     }, */{
  //       title: language.translate.SIDEBAR__FINANCE__REPORT__PROFIT_AND_LOST,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/laporan/laba-rugi',
  //     }, {
  //       title: language.translate.SIDEBAR__FINANCE__REPORT__BALANCE,
  //       icon: 'icon-angle-right',
  //       className: 'menu__left-icon--small',
  //       padding: 40,
  //       path: '/dashboard/keuangan/laporan/neraca',
  //     }],
  //   }],
  
  // // },{
  
  // },
  {
    title: "Tagihan Siswa",
    className: "menu__left-icon--small",
    path: "/dashboard/tagihan-siswa",
    iconImage: financeIcon,
    menu: [
      // {
      //   title: "Nama Pembayaran",
      //   icon: "icon-angle-right",
      //   className: "menu__left-icon--small",
      //   padding: 20,
      //   path: "/dashboard/tagihan-siswa/nama-pembayaran",
      // },
      {
        title: "Buat Invoice",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/create-invoice",
      },
      {
        title: "Pembayaran",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/pembayaran",
        // permission: "view_student",
      },
      {
        title: "Riwayat Pembayaran",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/riwayat",
        // permission: "view_student",
      },
      {
        title: "Rincian Pembayaran",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/rincian-pembayaran",
        // permission: "view_student",
      },
      {
        title: "Laporan Pembayaran",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/laporan-pembayaran",
      },
      {
        title: "Tunggakan",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/tunggakan",
      },
      {
        title: "Laporan Pembayaran PPDB",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/laporan-pembayaran-ppdb",
      },
    ],
  },
  {
    title: language.translate.SIDEBAR__SETTING,
    iconImage: settingIcon,
    path: "/dashboard/pengaturan",
    menu: [
      {
        title: "Ganti Kata Sandi",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/pengaturan/ganti-kata-sandi",
      },
      {
        title: "Identitas Unit Sekolah",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/pengaturan/identitas-unit-sekolah",
      },
      {
        title: "Tahun Ajaran Baru",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/pengaturan/tahun-ajaran",
      },
      {
        title: "Pembentukan Kelas",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/pengaturan/kelas",
      },
      {
        title: "Import Daftar Siswa",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/pengaturan/import-daftar-siswa",
      },
    ],
  },
  // {
  //   title: language.translate.SIDEBAR__PERSONAL,
  //   iconImage: personalIcon,
  //   path: "/dashboard/personalia",
  //   menu: [
  //     {
  //       title: language.translate.SIDEBAR_PERSONAL_STAFF_LIST,
  //       path: "/dashboard/personalia/data-staff",
  //       icon: "icon-minus",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //       permission: "data_staff",
  //     },
  //     {
  //       title: language.translate.SIDEBAR_PERSONAL_STAFF_LIST_UNIT,
  //       path: "/dashboard/personalia/data-staff-unit",
  //       icon: "icon-minus",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //       permission: "data_staff_unit",
  //     },
  //     // {
  //     //   title: language.translate.SIDEBAR_PERSONAL_APPROVAL_SK,
  //     //   path: '/dashboard/personalia/data-staff',
  //     //   icon: 'icon-minus',
  //     //   className: 'menu__left-icon--small',
  //     //   padding: 20,
  //     //   permission: 'data_staff',
  //     // },
  //     {
  //       title: language.translate.SIDEBAR_PERSONAL_MANAGE_PAYROLL,
  //       icon: "icon-minus",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //       path: "/dashboard/personalia/data-payroll",
  //       menu: [
  //         {
  //           title: language.translate.SIDEBAR_PERSONAL_POTONGAN_INTERN,
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/personalia/data-payroll/potongan-intern",
  //           permission: "input_potongan_intern",
  //         },
  //         {
  //           title: language.translate.SIDEBAR_PERSONAL_PAYROLL_LIST,
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/personalia/data-payroll/payroll",
  //           permission: "slip_gaji",
  //         },
  //         // {
  //         //   title: language.translate.SIDEBAR_PERSONALREPORT_SALARY,
  //         //   icon: 'icon-angle-right',
  //         //   className: 'menu__left-icon--small',
  //         //   padding: 40,
  //         //   path: '/dashboard/personalia/laporan/pengisian-payroll',
  //         // }
  //       ],
  //     },
  //     {
  //       title: language.translate.SIDEBAR_PERSONAL_REPORT,
  //       icon: "icon-minus",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //       menu: [
  //         //   {
  //         //   title: language.translate.SIDEBAR_PERSONALREPORT_SALARY,
  //         //   icon: 'icon-angle-right',
  //         //   className: 'menu__left-icon--small',
  //         //   padding: 40,
  //         //   path: '/dashboard/personalia/laporan/slip-gaji',
  //         // },
  //         {
  //           title: language.translate.SIDEBAR_PERSONALREPORT_SALARY,
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/personalia/laporan/payroll",
  //           permission: "view_payroll_report",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const SIDEBAR_ADMISSION_MENU = [
  {
    title: language.translate.SIDEBAR__HOME,
    iconImage: homeIcon,
    path: "/admission",
  },
];