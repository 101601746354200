import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';
import daftar_siswa from '../../../assets/img/akademi/DataSiswa/daftar-siswa.png';
import catat_siswa_keluar from '../../../assets/img/akademi/DataSiswa/catat-siswa-keluar.png';
import catat_siswa_pindah from '../../../assets/img/akademi/DataSiswa/catat-siswa-pindah.png';

export default class DataSiswa extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickNomorIndukSiswa = this.onClickNomorIndukSiswa.bind(this);
    this.onClickDaftarSiswaKelas = this.onClickDaftarSiswaKelas.bind(this);
    this.onClickCatatSiswaKeluar = this.onClickCatatSiswaKeluar.bind(this);
    this.onClickPembatalanSiswa = this.onClickPembatalanSiswa.bind(this);
    this.onClickDaftarRiwayatSiswa = this.onClickDaftarRiwayatSiswa.bind(this);
    this.onClickLaporanPPDB = this.onClickLaporanPPDB.bind(this);
    this.onClickCatatSiswaPindah = this.onClickCatatSiswaPindah.bind(this);

  }

  onClickNomorIndukSiswa() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/nis');
  }

  onClickDaftarSiswaKelas() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/daftar-siswa');
  }

  onClickCatatSiswaPindah() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/data-siswa/catat-siswa-pindah');
  }

  onClickCatatSiswaKeluar() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/data-siswa/catat-siswa-keluar');
  }

  onClickPembatalanSiswa() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/data-siswa/pembatalan-siswa-keluar');
  }

  onClickDaftarRiwayatSiswa() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/data-siswa/daftar-riwayat-siswa');
  }

  onClickLaporanPPDB() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/data-siswa/daftar-siswa-ppdb');
  }




  render() {
    return (
      <div className="akademi">
        <div className="akademi__list">
          <button onClick={this.onClickDaftarSiswaKelas}>
            <img src={daftar_siswa} alt="DataKelas" />
            <h3>Daftar Siswa</h3>
          </button>
        </div>
        {/* <div className="akademi__list">
          <button onClick={this.onClickDaftarRiwayatSiswa}>
            <img src={budgeting} alt="DataKelas" />
            <h3>Daftar Riwayat Siswa</h3>
          </button>
        </div> */}
        <div className="akademi__list">
          <button onClick={this.onClickLaporanPPDB}>
            <img src={daftar_siswa} alt="LaporanPPDB" />
            <h3>Laporan PPDB</h3>
          </button>
        </div>
        {/* <div className="akademi__list">
          <button onClick={this.onClickCatatSiswaPindah}>
            <img src={catat_siswa_pindah} alt="CatatSiswaPindah" />
            <h3>Catat Siswa Pindah</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickCatatSiswaKeluar}>
            <img src={catat_siswa_keluar} alt="DataKelas" />
            <h3>Catat Siswa Keluar</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickPembatalanSiswa}>
            <img src={budgeting} alt="DataKelas" />
            <h3>Pembatalan Siswa Keluar</h3>
          </button>
        </div> */}
     


      </div>
    );
  }
}
DataSiswa.propTypes = {
  history: PropTypes.object.isRequired,
};
