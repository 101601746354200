import React from "react";
import PropTypes from "prop-types";
import { noop, map, isEqual, isEmpty, capitalize } from "lodash";
import { Button, Pagination } from "../../components/base";
import BaseTable from "../../components/BaseTable/BaseTable.component";
import { formatData, normalizeAmount } from "../../utils/transformer.util";
import language from "../../languages";
import { Input, Select, SearchSelect } from "../../components/base/index";
import { errorAlert } from '../../utils/alert.util';
import { LIST_PENDAFTARAN_PPDB_LIST_TABLE_FIELDS } from "../../constants/Student/student.constant";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class ListPendaftaranPPDB extends BaseTable {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickRutin = this.onClickRutin.bind(this);
    this.onClickTidakRutin = this.onClickTidakRutin.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this.getYearsOfEducationsOptions = this.getYearsOfEducationsOptions.bind(this);
    this._initializeData = this._initializeData.bind(this);

    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          // periods_id: "",
          levels: [],
          classrooms_id: "",
        },
        error: {
          classrooms: "",
          levels: "",
        },
      },
      param: {},
      filtered: false,
    };
  }

  componentDidMount() {
    this._initializeData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _initializeData() {
    const { user } = this.props;
    const { content} = this.state;
    const { school_unit } = user;
    const paramTypes = ['classrooms','levels', 'classrooms'];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    await this._getStudentOptions( {units_id: [school_unit.id] });
    await this.getYearsOfEducationsOptions({units_id: school_unit.id });  

    if (isEmpty(content.list)) {
      await this._onSearchContent({ page: 1 });
    }
    
  }
  
  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    const { students_id = "", entrance_year } = filters;
    var year = ""
    if (entrance_year) {
      year = entrance_year.toString().substring(2);
    }

    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListPPDBOffline, getYearsOfEducationsOptions, user } =
            this.props;
          const { organizations_id, school_unit } = user;
          const { levels_id, units_id } = school_unit;

          const result = await handleListPPDBOffline({
            ...params,
            year: year,
            organizations_id: 3,
            units_id: school_unit.id,
          });
          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              // currentPage,
              // total,
              // totalPage,
            },
          });
          // }
        } catch (err) {
          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
        // }
      }
    );
  }

  async getYearsOfEducationsOptions(filters={}) {
    const { getYearsOfEducationsOptions } = this.props;
    const res = await getYearsOfEducationsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran selanjutnya
        if (res[idx+1]) {
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              entrance_year: res[idx+1].value,
            },
          }))
        }
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: 3,
          });
        }
        // if (name === 'classes_id') {
        //   this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id: 3 });
        // }
        if (name === "classrooms_id" && !inputArray) {
          this._setClassrooms(value);
        }
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
      }
    );
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }

  async handleGetData(payload = {}) {
    const { handleListPPDBOffline, user } = this.props;
    const { units_id, workingUnit, id, user_group, profile } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }
    const res = await handleListPPDBOffline({
      ...payload,
      user_id: id,
      organizations_id: 3,
    });

    this.setState({
      list: res,
    });
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics } = this.props;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = 3;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getStudentOptions(filters = {}) {
    const { getStudentOptions, user } = this.props;
    const { school_unit } = user;
    const res = await getStudentOptions(filters);
    filters.units_id = school_unit.id;
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  onClickAdd() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/pendaftaran-ppdb");
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/pendaftaran-ppdb", {
      data: val,
      isEdit: true,
    });
  }

  onClickRutin(val) {
    const { history } = this.props;
    if(val.no_va == "" || val.no_va == null){
      errorAlert({
        title: 'No VA belum tersedia.',
        errorMessage: 'Mohon masukkan nomor pendaftaran di Data Siswa'
      });
    }else{
      history.push("/dashboard/tagihan-siswa/create-invoice/rutin", {
        data: val,
        // isEdit: true,
      });
    }
  }

  onClickTidakRutin(val) {
    const { history } = this.props;
    if(val.no_va == "" || val.no_va == null){
      errorAlert({
        title: 'No VA belum tersedia.',
        errorMessage: 'Mohon masukkan nomor pendaftaran di Data Siswa'
      });
    }else{
      history.push("/dashboard/tagihan-siswa/create-invoice/tidak-rutin", {
        data: val,
        // isEdit: true,
      });
    }
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _renderButtons(data) {
    const { filters } = this.state

    data.name = data.fullname;
    data.entrance_year = filters.entrance_year;

    return (
      <td>
        <div className="table__actions">
          {data.upload ? (
            <Button
              onClick={() => this.onClickDownload(data)}
              title="Download"
            />
          ) : (
            <div></div>
          )}
          {/* {user_group.id === 2? */}
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />
          <Button onClick={() => this.onClickRutin(data)} title="SPP" />
          <Button onClick={() => this.onClickTidakRutin(data)} title="DPP/UPP" />
        </div>
      </td>
    );
  }

  render() {
    const { list = {}, content, param, filters, form } = this.state;
    const { handleGetFundRequest, handleDeleteFundRequest, user } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const { value } = form;

    // const { students_id = "", entrance_year = "" } = filters;
    const dataset = {
      toggle: "modal",
    };
    // var nextyear = new Date().getFullYear() + 1;
    // var month = new Date().getMonth() + 1;
    // if (month < 7) {
    //   nextyear -= 1;
    // }

    // filters.entrance_year = nextyear;

    return (
      <div className="fund-request">
        <div className="beginning-balance__button-wrapper">
          <Button
            title="Tambah Siswa"
            onClick={this.onClickAdd}
            data={dataset}
          />
        </div>
        <br></br>
        <div className="isi-bidang__custom-form">
          <div className="isi-bidang__custom-form-column">
            <div className="isi-bidang__custom-form-row__field">
              <Select
                name="entrance_year"
                label="Tahun Ajaran"
                placeholder={"Pilihan"}
                onChange={this._onChangeFilter}
                data={param.periods}
                value={filters.entrance_year}
                disabled
              />

              <div className="isi-bidang__custom-form-row__field"></div>
              {/* <SearchSelect
                  noMargin
                  async={false}
                  name="students_id"
                  list={param.student}
                  inputArray
                  onClick={this._onChangeFilter}
                  placeholder="Pilih Nama Siswa"
                  value={filters.students_id}
                  // labelName="label"
                  // valueName="value"
                  rightIcon="icon-search"
                  label= "Nama Siswa"
                  /> */}
            </div>
          </div>
        </div>
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {map(
                  LIST_PENDAFTARAN_PPDB_LIST_TABLE_FIELDS.label,
                  (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {map(content.list, (data, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(
                    LIST_PENDAFTARAN_PPDB_LIST_TABLE_FIELDS.value,
                    (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td
                            style={{ textAlign: "center" }}
                            key={`table_index_${fieldIdx}_${idx}`}
                          >
                            {idx + 1}
                          </td>
                        );
                      }
                      if (field.attribute === "nisn") {
                        return (
                          <td style={{ textAlign: "center" }}>{data.nisn}</td>
                        );
                      }
                      if (field.attribute === "no_va") {
                        return (
                          <td style={{ textAlign: "center" }}>{data.no_va}</td>
                        );
                      }
                      if (field.type === "link") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <a
                              href={"https://" + data.hyperlink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {data.hyperlink}
                            </a>
                          </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                        );
                      }
                      if (field.attribute === "fullname") {
                        return (
                          <td key={`table_index_${fieldIdx}_${idx}`} style={{textTransform:"capitalize"}}>
                            {capitalize(data.fullname)}
                          </td>
                        );
                      }
                      return (
                        <td
                          className={field.type === "number" ? "nominal" : ""}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(data, field)}
                        </td>
                      );
                    }
                  )}
                  {this._renderButtons(data)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
          </p>
          <Pagination
            totalPage={content.totalPage}
            currentPage={content.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
ListPendaftaranPPDB.propTypes = {
  handleDelete: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
ListPendaftaranPPDB.defaultProps = {
  handleDelete: noop,
  user: null,
};
