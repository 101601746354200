import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop } from "lodash";
import { Input, Select, InputDate, Textarea } from "../../../components/base/index";

export default class PPDBOrtuWaliForm extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.state = {
      original: {
        sub_districts: props.sub_districts || [],
        districts: props.districts || [],
        cities: props.cities || [],
        provinces: props.provinces || [],
      },
      options: {
        sub_districts: [],
        districts: [],
        cities: [],
        provinces: [],
      },
    };
  }

  _onFormChange(e) {
    const { onChange } = this.props;
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change, () => {
      console.log(this.state);
    });

    onChange(e);
  }

  render() {
    const { form, param } = this.props;

    if (form.value.guardian_provincess_id == "") {
      form.value.guardian_city_id = "";
    }
    if (form.value.guardian_city_id == "") {
      form.value.guardian_districts_id = "";
    }
    if (form.value.guardian_districts_id == "") {
      form.value.guardian_sub_districts_id = "";
    }

    return (
      <div className="manage-registration__custom-form">
        <h1>Wali</h1>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="guardian_name"
              label="Nama Lengkap *"
              onChange={this._onFormChange}
              value={form.value.guardian_name}
              error={form.error.guardian_name || ""}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="guardian_nik"
              label="NIK *"
              onChange={this._onFormChange}
              value={form.value.guardian_nik}
              error={form.error.guardian_nik || ""}
              isRequired
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="guardian_religions_id"
              label="Agama"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.guardian_religions_id}
              error={form.error.guardian_religions_id || ""}
              data={param.religions}
              // isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="guardian_pob"
              label="Tempat Lahir *"
              onChange={this._onFormChange}
              value={form.value.guardian_pob}
              error={form.error.guardian_pob || ""}
              isRequired
            />
            <div className="manage-registration__custom-form-margin-left">
              <InputDate
                type="date"
                name="guardian_dob"
                label="Tanggal Lahir"
                onChange={this._onFormChange}
                value={form.value.guardian_dob}
                error={form.error.guardian_dob || ""}
                // isRequired
              />
            </div>
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            {/* <Select
              name="guardian_citizenships_id"
              label="Kewarganegaraan"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.guardian_citizenships_id}
              error={form.error.guardian_citizenships_id || ""}
              data={param.citizenships}
              // isRequired
            /> */}
            <Select
              name="guardian_educations_id"
              label="Pendidikan Terakhir"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.guardian_educations_id}
              error={form.error.guardian_educations_id || ""}
              data={param.educations}
              // isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row-input__field">
            <Input
              type="text"
              name="guardian_phone"
              label="No Telpon *"
              onChange={this._onFormChange}
              value={form.value.guardian_phone}
              error={form.error.guardian_phone || ""}
              isRequired
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="guardian_occupations_id"
              label="Pekerjaan"
              onChange={this._onFormChange}
              data={param.occupations}
              placeholder="Pilihan"
              value={form.value.guardian_occupations_id}
              error={form.error.guardian_occupations_id || ""}
              // isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="guardian_salary"
              label="Penghasilan *"
              onChange={this._onFormChange}
              value={form.value.guardian_salary}
              error={form.error.guardian_salary || ""}
              isRequired
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <Textarea
            type="text"
            name="guardian_address"
            label="Alamat Tempat Tinggal *"
            onChange={this._onFormChange}
            value={form.value.guardian_address}
            error={form.error.guardian_address || ""}
            isRequired
          />
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              name="guardian_status"
              label="Status/Kondisi"
              onChange={this._onFormChange}
              value={form.value.guardian_status}
              error={form.error.guardian_status || ""}
            />
          </div>

          <div className="manage-registration__custom-form-row__field">
          </div>
        </div>

        {/* <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              name="guardian_provincess_id"
              value={form.value.guardian_provincess_id}
              error={form.error.guardian_provincess_id || ""}
              placeholder="Pilihan"
              label="Propinsi"
              onChange={this._onFormChange}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            {form.value.guardian_provincess_id && (
              <Input
                name="guardian_city_id"
                label="Kabupaten/Kota"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.guardian_city_id}
                error={form.error.guardian_city_id || ""}
              />
            )}
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            {form.value.guardian_city_id && (
              <Input
                name="guardian_districts_id"
                label="Kecamatan"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.guardian_districts_id}
                error={form.error.guardian_districts_id || ""}
              />
            )}
          </div>

          <div className="manage-registration__custom-form-row__field">
            {form.value.guardian_districts_id && (
              <Input
                name="guardian_sub_districts_id"
                label="Kelurahan"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.guardian_sub_districts_id}
                error={form.error.guardian_sub_districts_id || ""}
              />
            )}
          </div>
        </div> */}
      </div>
    );
  }
}
PPDBOrtuWaliForm.propTypes = {
  idx: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  list: PropTypes.array.isRequired,
  religions: PropTypes.array,
  citizenships: PropTypes.array,
  educations: PropTypes.array,
  occupations: PropTypes.array,
};
PPDBOrtuWaliForm.defaultProps = {
  onChange: noop,
  guardian_city_id: [],
  guardian_districts_id: [],
  religions: [],
  citizenships: [],
  educations: [],
  occupations: [],
};
