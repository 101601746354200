import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import mutasi_siswa from '../../assets/img/akademi/mutasi-siswa.png';

export default class PenerimaanSiswa extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickPendaftaranPPDB = this.onClickPendaftaranPPDB.bind(this);
    this.onClickPendaftaran = this.onClickPendaftaran.bind(this);
  }

  onClickPendaftaranPPDB() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/list-pendaftaran-ppdb');
  }

  onClickPendaftaran() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/list-pendaftaran-siswa');
  }

  render() {
    const { user } = this.props;
    const { school_unit } = user;
    const { ppdb_online } = school_unit;

    return (
      <div className="akademi">
        {ppdb_online == 0 && (
          <div className="akademi__list">
            <button onClick={this.onClickPendaftaranPPDB}>
              <img src={mutasi_siswa} alt="DataKelas" />
              <h3>Penerimaan Siswa Baru</h3>
            </button>
          </div>
        )}
        <div className="akademi__list">
          <button onClick={this.onClickPendaftaran}>
            <img src={mutasi_siswa} alt="DataKelas" />
            <h3>Mutasi Siswa</h3>
          </button>
        </div>
      </div>
    );
  }
}
PenerimaanSiswa.propTypes = {
  history: PropTypes.object.isRequired,
};
